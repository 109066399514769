// import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
export function useCommon () {
  const w = computed(() => {
    w
    if (window.innerWidth > 1000 && window.innerWidth < 1200) {
      return '50%'
    } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
      return '40%'
    } else {
      return window.innerWidth - window.innerWidth / 10 + 'px'
    }
  })

  const arrayDiffByKey = (oldArray,newArray,key) => {
   return newArray.filter(n =>
      oldArray.every(o => !o[key].includes(n[key]))
    )
  }

  return {
    w,
    arrayDiffByKey
  }
}
export default useCommon
