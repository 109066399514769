<template>
  <el-dialog v-model="dialogPayVisible$" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :title="`${dialogPayTitle$.text}．${dialogPayTitle$.price}`" :width="`${w}`"
    custom-class="subscription-dialog">

    <template #header>
      <div class="b-users__item__cover__wrapper">
        <div class="rounded img-fluid" :style="`height: 200px;background-size: cover;background-image: url(${dialogCurrentUser$?.profile_banner});`">
        </div>
      </div>
      <div class="b-profile__user">
        <div class="g-avatar">
          <el-avatar :size="100">
            <img :src="dialogCurrentUser$?.avatar" />
          </el-avatar>
        </div>
        <div class="b-profile__user__info m-with-status">
          <div class="b-profile__names">
            <div class="b-username-row">
              <router-link :to="`/${dialogCurrentUser$?.user_account}`" class="b-username">
                <div class="g-user-name m-verified m-lg-size"> {{ dialogCurrentUser$?.user_name }} </div>
              </router-link>
            </div>
            <div class="b-username-row m-width-limit">
              <router-link :to="`/${dialogCurrentUser$?.user_account}`">
                <div class="g-user-username">{{ dialogCurrentUser$?.user_account }} </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- <span>{{ dialogCurrentUser$ }}</span> -->
    <div v-if="isCard$" id="isCard">

      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">可檢視鎖定的貼文內容</div>
      </div>
      <div v-if="step === 2">
        <div id="supscriptionPayMethod">
          <h5 class="pt-3">{{ $t('label.chooseThePaymentMethod') }}</h5>
          <el-select class="w-100" :placeholder="$t('label.pleaseChooseAPaymentMethod')" v-model="selectedPayMethod">
            <el-option v-for="item in PayOptions" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div id="payDesc">
          <div class="desc-list">
            <div class="d-flex justify-content-start">
              <span>{{ selectedPayOptionText }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <span>
                  {{ $t('label.theAmount') }}：{{ toCurrency(selectedPayPrice) }}</span>
              </div>
              <div>
                <span>{{ $t('label.tax') }}：{{ toCurrency(taxPrice) }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <span>{{ $t('label.paidInTotal') }}：{{ toCurrency(total) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isCard$" id="isNotCard">
      <div class="">
        <div class="content-btn d-grid p-2">
          <button type="submit" class="btn btn-primary rounded-pill" @click="$router.push({ name: 'social.add-card' })">
            添加信用卡資訊
          </button>
        </div>
      </div>
    </div>
    <template #footer>
      <div v-if="isCard$" id="isCardFooter" class="dialog-footer">
        <el-button text @click="close">{{ $t('label.cancel') }}</el-button>
        <el-button v-if="step === 2" :loading="onLoading" type="primary" @click="orderCheckout(
          selectedPayMethod, selectedPayId, selectedPayType, number, payPrice
        )">{{ $t('label.payment') }}</el-button>
      </div>
      <div v-if="!isCard$" id="isNotCardFooter" class="dialog-footer">
        <el-button text type="primary" @click="store.dispatch('User/dialogPayAction', false)">關閉</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Check } from '@element-plus/icons-vue'
import { useConvert } from '@/utils/useConvert'
import { useCheckout } from '@/utils/useCheckout'

export default {
  name: 'MsgPay',
  components: {
    Check
  },
  props: {
    items: { type: Array, default: () => [] },
    payPrice: { type: Number },
    payId: { type: String }
  },
  setup(props) {
    const { orderCheckout, onLoading, PayOptions } = useCheckout()
    const { toCurrency, payIdToString } = useConvert()
    const store = useStore()
    const step = ref(1)
    const selectedPayOptionText = ref('') // 已選的方案選項文字
    const selectedPayId = ref(0) // 付款id

    const selectedPayMethod = ref('ewallet') // 付款方式
    const selectedPayType = ref(7) // 訂單類型
    const selectedPayPriceFormat = ref(0) // 金額格式化
    const selectedPayPrice = ref(0) // 訂單金額
    const taxPercentage = ref(0.05) // 稅金％
    const taxPrice = ref(0) // 稅金

    const total = ref(0) // 總金額
    const iconSize = ref(17)
    const dialogPayVisible$ = computed(() => store.state.User.dialogPayVisible)
    const dialogPayTitle$ = computed(() => store.state.User.dialogPayTitle)
    const dialogCurrentUser$ = computed(() => store.state.User.dialogItem)
    const isCard$ = computed(() => store.state.User.user.is_card)

    const w = computed(() => {
      if (window.innerWidth > 1000 && window.innerWidth < 1200) {
        return '50%'
      } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
        return '40%'
      } else {
        return window.innerWidth - window.innerWidth / 10 + 'px'
      }
    })

    // 监听触发
    watch(dialogPayVisible$, (newVal, oldVal) => {
      if (newVal) {
        console.log('watch', oldVal)
        console.log('payPrice', props.payPrice)
        console.log('payId', props.payId)

        selectedOption(2, 7, '解除鎖定訊息', props.payPrice, props.payId)
      }
    })

    // _step 步驟
    // type 訂單類型
    // otpionText 訂閱選項文字
    // price 金額
    // payId 付款ID
    const selectedOption = (_step, type, otpionText, price, id) => {
      selectedPayId.value = id
      console.log('selectedOption', type, price, selectedPayId.value)

      // dialogPayTitle$.value.item[`${payIdToString(type)}_id`]

      if (_step === 2) {
        selectedPayOptionText.value = otpionText
        selectedPayType.value = type
        selectedPayPriceFormat.value = price
        selectedPayPrice.value = Number(price)
        taxPrice.value = Math.round(selectedPayPrice.value * 0.05) // 稅金
        total.value = taxPrice.value + selectedPayPrice.value // 總金額
        step.value = 2
      } else {
        setDefaultOpt()
        step.value = 1
      }
    }

    const close = () => {
      step.value = 1
      setDefaultOpt()
      store.dispatch('User/dialogPayAction', false)
    }

    const setDefaultOpt = () => {
      selectedPayId.value = 0
      selectedPayOptionText.value = ''
      selectedPayType.value = 0
      selectedPayPriceFormat.value = 0
      selectedPayPrice.value = 0
      taxPrice.value = 0 // 稅金
      total.value = 0 // 總金額
    }

    return {
      setDefaultOpt,
      close,
      selectedOption,
      dialogCurrentUser$,
      dialogPayVisible$,
      w,
      iconSize,
      PayOptions,
      store,
      dialogPayTitle$,
      isCard$,
      step,
      selectedPayOptionText,
      selectedPayMethod,
      selectedPayType,
      selectedPayPrice,
      selectedPayPriceFormat,
      taxPrice,
      total,
      taxPercentage,
      toCurrency,
      orderCheckout,
      selectedPayId,
      payIdToString,
      onLoading
    }
  }
}
</script>
<style scoped>
.pay-desc {
  font-size: 10px;
}
</style>